import React, {useEffect, useState} from 'react';
import {Button, Container, Form, Table} from "react-bootstrap";
import {Link, useNavigate, useParams} from "react-router-dom";
import {CATEGORY_TYPE_PLAYLISTS, CATEGORY_TYPE_SOUNDS, IMAGES_EXT} from "../utils/consts";
import {getAllPlaylists} from "../http/playlistAPI";
import {getAllSounds} from "../http/soundAPI";
import {createCategory, deleteCategory, getOneCategory, updateCategory} from "../http/categoryAPI";
import {Typeahead} from "react-bootstrap-typeahead";

const EditCategory = ({setLoader, handleNotification}) => {
	const navigate = useNavigate();
	const {id} = useParams();
	const [category, setCategory] = useState({});
	const [entities, setEntities] = useState([]);
	const [searchQuery, setSearchQuery] = useState([]);

	useEffect(() => {
		if (+id) {
			setLoader({status: true, message: 'Получение категории'});
			getOneCategory(id)
				.then(res => {
					// const localCategory = {...res};
					// if (res.type === CATEGORY_TYPE_PLAYLISTS){
					//     localCategory.entities = JSON.stringify(JSON.parse(localCategory.playlists));
					//     delete localCategory['playlists'];
					// }else{
					//     localCategory.entities = JSON.stringify(JSON.parse(localCategory.sounds));
					//     delete localCategory['sounds'];
					// }
					// console.log(localCategory);
					console.log(res);
					setCategory({
						id: res.id,
						name: res.name,
						description: res.description,
						type: res.type,
						entities: res.sounds || res.playlists
					});
				})
				.catch(err => {
					handleNotification(err, 'danger');
					navigate('/categories')
				})
				.finally(() => setLoader(null));
		} else {
			setCategory({
				name: '',
				description: '',
				type: CATEGORY_TYPE_PLAYLISTS,
				entities: []
			});
		}

	}, []);

	useEffect(() => {
		setLoader({status: true, message: 'Получение сущностей'})
		if (category.type === CATEGORY_TYPE_PLAYLISTS)
			getAllPlaylists()
				.then(res => setEntities([...res]))
				.catch(err => {
					handleNotification(err, 'danger');
					navigate('/categories')
				})
				.finally(() => setLoader(null));
		else if (category.type === CATEGORY_TYPE_SOUNDS)
			getAllSounds()
				.then(res => setEntities([...res]))
				.catch(err => {
					handleNotification(err, 'danger');
					navigate('/categories')
				})
				.finally(() => setLoader(null));
	}, [category.type]);

	const onSelectType = (e) => {
		setCategory({...category, type: e.target.value, entities: []});
	}

	const onSelectEntity = () => {
		const name = searchQuery[0];
		const entity = entities.find(el => el.name === name);
		if (!entity)
			return handleNotification('Сущность не найдена', 'danger');
		if (category.entities.includes(el => el.id === entity.id))
			return handleNotification('Сущность уже добавлена', 'danger');
		else {
			setCategory({...category, entities: [...category.entities, entity]});
		}
		setSearchQuery([]);
	}

	const changePosition = (soundId, e) => {
		if (e.key !== 'Enter')
			return;

		let newPosition = +e.target.value;

		if (isNaN(newPosition) || newPosition <= 0)
			newPosition = 1;

		const currentEntity = entities.find(el => el.id === soundId);

		if (newPosition >= category.entities.length)
			setCategory({
				...category,
				entities: [...category.entities.filter(el => el.id !== currentEntity.id), currentEntity]
			})
		else {
			const localEntities = [];
			const filtered = category.entities.filter(el=>el.id !== currentEntity.id);

			for (let i = 0; i < filtered.length; i++) {
				if (i + 1 === newPosition)
					localEntities.push(currentEntity);
				localEntities.push(filtered[i]);
			}
			setCategory({...category, entities: [...localEntities]});
		}

		e.target.value = '';
	}

	const saveCategory = () => {
		if (!category.name)
			return handleNotification('Введите имя', 'danger');

		setLoader({status: true, message: 'Сохранение категории'});
		if (+id)
			updateCategory(id, category)
				.then(() => handleNotification('Категория сохранена', 'success'))
				.catch(err => handleNotification(err, 'danger'))
				.finally(() => {
					setLoader(null);
					navigate('/categories')
				});
		else
			createCategory(category)
				.then(() => handleNotification('Категория сохранена', 'success'))
				.catch(err => handleNotification(err, 'danger'))
				.finally(() => {
					setLoader(null);
					navigate('/categories')
				});
	}

	const deleteCurrentCategory = () => {
		setLoader({status: true, message: 'Удаление категории'});
		deleteCategory(id)
			.then(() => handleNotification('Категория удалена'))
			.catch(err => handleNotification(err, 'danger'))
			.finally(() => {
				setLoader(null)
				navigate('/categories')
			})
	}

	return (
		<Container>
			<h1>{+id ? 'Изменение' : 'Создание'} категории</h1>
			<Form>
				<Form.Group className={'mt-2'}>
					<Form.Label>Имя</Form.Label>
					<Form.Control
						value={category.name}
						onChange={e => setCategory({...category, name: e.target.value})}
						placeholder={'Имя'}
					/>
				</Form.Group>

				<Form.Group className={'mt-2'}>
					<Form.Label>Описание</Form.Label>
					<Form.Control
						value={category.description}
						onChange={e => setCategory({...category, description: e.target.value})}
						placeholder={'Имя'}
					/>
				</Form.Group>

				<Form.Group className={'mt-2'}>
					<Form.Label>Тип категории</Form.Label>
					<Form.Select value={category.type} disabled={!!+id} onChange={onSelectType}>
						<option value={CATEGORY_TYPE_PLAYLISTS}>Плейлисты</option>
						<option value={CATEGORY_TYPE_SOUNDS}>Звуки</option>
					</Form.Select>
				</Form.Group>

				<Form.Group className="mt-2">
					<Form.Label>{category.type === CATEGORY_TYPE_PLAYLISTS ? 'Плейлисты' : 'Звуки'}</Form.Label>
					<Typeahead
						id={'add-sounds'}
						options={entities.filter(entity => !category.entities.some(el => entity.id === el.id)).map(el => el.name)}
						selected={searchQuery}
						onChange={setSearchQuery}
						maxResults={5}
					/>
					<Button className={'mt-2'}
					        onClick={onSelectEntity}>Добавить {category.type === CATEGORY_TYPE_PLAYLISTS ? 'плейлист' : 'звук'}</Button>

					<Table>
						<thead>
						<tr>
							<th>#</th>
							<th>Имя</th>
							<th>Позиция</th>
							<th>Управление</th>
						</tr>
						</thead>
						<tbody>
						{category.entities?.map((el, index) =>
							<tr key={`${el.name}-${el.id}`}>
								<td>{index + 1}</td>
								<td>
									<Link
										to={`/${category.type === CATEGORY_TYPE_PLAYLISTS ? 'playlists' : 'sounds'}/${el.id}`}
										target={'_blank'}>
										{el.name}
									</Link>
								</td>
								<td>
									<Form.Control
										type={"number"}
										placeholder={`${index + 1}`}
										onKeyDown={(e) => changePosition(el.id, e)}
									/>
								</td>
								<td>
									<Button
										onClick={() => setCategory({
											...category,
											entities: category.entities.filter(sound => sound.id !== el.id)
										})}
									>Удалить</Button>
								</td>
							</tr>
						)}
						</tbody>
					</Table>
				</Form.Group>
			</Form>

			<Button className={'mt-2'} onClick={saveCategory}>Сохранить</Button><br/>
			{!!+id && <Button className={'mt-2'} onClick={deleteCurrentCategory}>Удалить</Button>}
		</Container>
	);
};

export default EditCategory;