import {$authHost, $host} from "./index";

export const getAllPlaylists = async () => {
    const {data} = await $authHost.get('api/playlist');
    return data;
}

export const getOnePlaylist = async (id) => {
    const {data} = await $host.get(`api/playlist/${id}`);
    return data;
}

export const createPlaylist = async (body) => {
    const {data} = await $authHost.post('api/playlist', body);
    return data;
}

export const updatePlaylist = async (id, body) => {
    const {data} = await $authHost.patch(`api/playlist/${id}`, body);
    return data;
}

export const deletePlaylist = async (id) => {
    const {data} = await $authHost.delete(`api/playlist/${id}`);
    return data;
}