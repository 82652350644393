import React, {useState} from 'react';
import {Container, Form, Button, Navbar} from 'react-bootstrap';
import {login} from "../http/userAPI";

const Login = ({setUser, handleNotification, setLoader}) => {
	const [inputForm, setInputForm] = useState({
		login: '',
		password: ''
	});

	const loginButtonHandler = () => {
		if (!inputForm.login || !inputForm.password)
			return handleNotification('Введите логин и пароль', 'danger');

		setLoader({status: true, message: 'Авторизация'});
		login(inputForm.login, inputForm.password)
			.then(setUser)
			.catch(err => {
				localStorage.removeItem('token');
				handleNotification(err?.response?.data?.message || err.message, 'danger');
			})
			.finally(() => setLoader(null));
	}

	return (
		<Container>
			<div>
				<h1>Авторизация</h1>
				<Form>
					<Form.Group className="mb-3" controlId="formBasicEmail">
						<Form.Label>Логин</Form.Label>
						<Form.Control placeholder="Логин" value={inputForm.login}
						              onChange={e => setInputForm({...inputForm, login: e.target.value})}
						/>
					</Form.Group>

					<Form.Group className="mb-3" controlId="formBasicPassword">
						<Form.Label>Пароль</Form.Label>
						<Form.Control type="password" placeholder="Пароль" value={inputForm.password}
						              onChange={e => setInputForm({...inputForm, password: e.target.value})}
						/>
					</Form.Group>

					<Button variant="primary" onClick={loginButtonHandler}>
						Войти
					</Button>
				</Form>
			</div>
		</Container>
	);
};

export default Login;
