import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {createSound, deleteSound, getOneSound, updateSound} from "../http/soundAPI";
import {Button, Container, Form} from "react-bootstrap";
import {IMAGES_EXT, SOUNDS_EXT} from "../utils/consts";
import {deletePlaylist} from "../http/playlistAPI";

const EditSound = ({setLoader, handleNotification}) => {
	const navigate = useNavigate();
	const {id} = useParams();
	const [currentSound, setCurrentSound] = useState({});
	const [dataImage, setDataImage] = useState();
	const [dataSound, setDataSound] = useState();

	useEffect(() => {
		if (+id !== 0) {
			setLoader({status: true, message: 'Получение звука'});
			getOneSound(id)
				.then(res => setCurrentSound({...res}))
				.catch(err => {
					handleNotification(err, 'danger');
					navigate('/sounds');
				})
				.finally(() => setLoader(null));
		} else {
			setCurrentSound({
				name: '',
				description: '',
				isPremium: false,
				volumeLevel: 50,
				sound: null,
				image: null
			});
		}
	}, []);

	const saveSound = () => {
		if (!currentSound.name)
			return handleNotification('Введите имя', 'danger');

		const formData = new FormData();

		if (+id === 0) {
			if (!currentSound.sound)
				return handleNotification('Отсутствует звук', 'danger');
			if (!currentSound.image)
				return handleNotification('Отсутствует изображение', 'danger');
		}
		if (currentSound.sound)
			formData.append('sound', currentSound.sound);
		if (currentSound.image)
			formData.append('image', currentSound.image);

		formData.append('name', currentSound.name);
		formData.append('description', currentSound.description);
		formData.append('isPremium', currentSound.isPremium);
		formData.append('volumeLevel', currentSound.volumeLevel);

		setLoader({status: true, message: 'Сохранение звука'});
		if (+id === 0)
			createSound(formData)
				.then(() => handleNotification('Звук сохранен', 'success'))
				.catch(err => handleNotification(err, 'danger'))
				.finally(() => {
					setLoader(null);
					navigate('/sounds')
				});
		else
			updateSound(id, formData)
				.then(() => handleNotification('Звук сохранен', 'success'))
				.catch(err => handleNotification(err, 'danger'))
				.finally(() => {
					setLoader(null);
					navigate('/sounds')
				});
	}

	const deleteCurrentSound = () => {
		setLoader({status: true, message: 'Удаление звука'});
		deleteSound(id)
			.then(() => handleNotification('Звук удален', 'success'))
			.catch(err => handleNotification(err, 'danger'))
			.finally(() => {
				setLoader(null);
				navigate('/');
			});
	}

	const onChangeFile = (event, fileType) => {
		const file = event.target.files[0];

		if (file) {
			setCurrentSound({...currentSound, [fileType]: file});

			const reader = new FileReader();

			reader.onloadend = () => {
				if (fileType === 'image')
					setDataImage(reader.result);
				if (fileType === 'sound')
					setDataSound(reader.result);
			};

			reader.readAsDataURL(file);
		}
	}

	return (
		<Container className={'mt-2'}>
			<h1>{+id ? 'Изменение' : 'Создание'} звука</h1>

			<Form>
				<Form.Group className={'mt-2'}>
					<Form.Label>Имя</Form.Label>
					<Form.Control
						value={currentSound.name}
						onChange={e => setCurrentSound({...currentSound, name: e.target.value})}
						placeholder={'Имя'}
					/>
				</Form.Group>

				<Form.Group className={'mt-2'}>
					<Form.Label>Описание</Form.Label>
					<Form.Control
						value={currentSound.description}
						onChange={e => setCurrentSound({...currentSound, description: e.target.value})}
						placeholder={'Описание'}
					/>
				</Form.Group>

				<Form.Group className={'mt-2'}>
					<Form.Label>Уровень громкости</Form.Label>
					<Form.Control
						type={'number'}
						value={currentSound.volumeLevel}
						min={0}
						max={100}
						step={1}
						onChange={e => {
							if (e.target.value > 100)
								return;
							setCurrentSound({...currentSound, volumeLevel: e.target.value})
						}}
						placeholder={'Уровень громкости'}
					/>
				</Form.Group>

				<Form.Group className={'mt-2'}>
					<Form.Label>Премиум</Form.Label>
					<Form.Check
						checked={currentSound.isPremium}
						onChange={e => setCurrentSound({...currentSound, isPremium: e.target.checked})}
					/>
				</Form.Group>

				<Form.Group className="mb-2">
					<Form.Label>Изображение</Form.Label>
					<Form.Control type={'file'}
					              accept={IMAGES_EXT.join(', ')}
					              onChange={(e) => onChangeFile(e, 'image')}
					/>
				</Form.Group>
				{(dataImage || currentSound.imageURL) && (
					<img className={'mb-2'} src={dataImage || currentSound.imageURL} alt="Image"
					     style={{maxWidth: '100%', maxHeight: '200px'}}/>
				)}

				<Form.Group className="mb-2">
					<Form.Label>Звук</Form.Label>
					<Form.Control type={'file'}
					              accept={SOUNDS_EXT.join(', ')}
					              onChange={(e) => onChangeFile(e, 'sound')}
					/>
				</Form.Group>
				{(dataSound || currentSound.soundURL) && (
					<audio className={'mb-2'} controls>
						<source src={dataSound || currentSound.soundURL}/>
					</audio>
				)}
			</Form>

			<Button className={'mt-2'} onClick={saveSound}>Сохранить</Button><br/>
			{!!+id && <Button className={'mt-2'} onClick={deleteCurrentSound}>Удалить</Button>}
		</Container>
	);
};

export default EditSound;